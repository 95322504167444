import { isMounted } from './helpers';

const key = 'user_data';

export const setUserData = value =>
  isMounted && localStorage.setItem(key, JSON.stringify(value));

export const getUserData = () =>
  isMounted &&
  localStorage.getItem(key) &&
  JSON.parse(localStorage.getItem(key));

export const clearUserData = () => isMounted && localStorage.removeItem(key);
