import qs from 'qs';

export const requestConfig = baseURL => ({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: params => qs.stringify(params),
  },
});
