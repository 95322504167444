import PropTypes from 'prop-types';
import { DM_Sans } from '@next/font/google';
import ReactModal from 'react-modal';

import { queryClient } from '@api/lambda/base';
import { QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SignaturesPaginationProvider } from 'context/SignaturesPaginationContext';
import { FormProvider } from '../context/FormContext';
import { MediaProvider } from '../context/MediaContext';
import { DocViewerProvider } from '../context/DocViewerContext';

import '../styles/global.scss';
import '../styles/typography.scss';

const font = DM_Sans({
  weight: ['400', '500', '700'],
});

export default function App({ Component, pageProps }) {
  useEffect(() => {
    ReactModal.setAppElement('#app');
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen />
      <SignaturesPaginationProvider>
        <MediaProvider>
          <FormProvider>
            <DocViewerProvider>
              <main className={font.className} id='app'>
                <Component {...pageProps} />
              </main>
            </DocViewerProvider>
          </FormProvider>
        </MediaProvider>
      </SignaturesPaginationProvider>
    </QueryClientProvider>
  );
}

App.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
};
