import PropTypes from 'prop-types';

import { createContext, useContext, useMemo, useState } from 'react';

const SignaturesPaginationContext = createContext({
  page: 1,
  setPage: () => {},
});

export const SignaturesPaginationProvider = ({ children }) => {
  const [page, setPage] = useState(1);

  const value = useMemo(
    () => ({
      page,
      setPage,
    }),
    [page, setPage],
  );
  return (
    <SignaturesPaginationContext.Provider value={value}>
      {children}
    </SignaturesPaginationContext.Provider>
  );
};

SignaturesPaginationProvider.propTypes = {
  children: PropTypes.any,
};

export const useSignaturesPaginationContext = () =>
  useContext(SignaturesPaginationContext);
