import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';

const MediaContext = createContext({
  video: {},
  setVideo: () => {},
  signature: '',
  setSignature: () => '',
});

export const MediaProvider = ({ children }) => {
  const [video, setVideo] = useState();
  const [signature, setSignature] = useState();
  const [pdf, setPdf] = useState();

  const value = useMemo(
    () => ({
      video,
      setVideo,
      signature,
      setSignature,
      pdf,
      setPdf,
    }),
    [video, setVideo, signature, setSignature, pdf, setPdf],
  );

  return (
    <MediaContext.Provider value={value}>{children}</MediaContext.Provider>
  );
};

MediaProvider.propTypes = {
  children: PropTypes.any,
};

export const useMediaContext = () => useContext(MediaContext);
