import PropTypes from 'prop-types';

import { createContext, useContext, useState } from 'react';

const DocViewerContext = createContext({
  userPhoneNumber: '',
  setUserPhoneNumber: () => {},
  userID: '',
  setUserID: () => {},
});

export const DocViewerProvider = ({ children }) => {
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [userID, setUserID] = useState('');

  return (
    <DocViewerContext.Provider
      value={{ userPhoneNumber, setUserPhoneNumber, userID, setUserID }}
    >
      {children}
    </DocViewerContext.Provider>
  );
};

DocViewerProvider.propTypes = {
  children: PropTypes.any,
};

export const useDocViewerContext = () => useContext(DocViewerContext);
