import axios from 'axios';
import config from '@config';

import { requestConfig } from '@api/requestConfig';
import { QueryClient } from '@tanstack/react-query';
import { getUserData } from 'localStorage/userData';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const axiosInstance = axios.create(requestConfig(config.api.host));

axiosInstance.interceptors.request.use(async req => {
  const userData = getUserData();
  if (userData) {
    req.headers.Authorization = `Bearer ${
      userData.jwt || userData.jwt_doc_viewer
    }`;
  }
  return req;
});

axiosInstance.interceptors.response.use(response => response.data);

export default axiosInstance;

export { queryClient };
