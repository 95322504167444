import PropTypes from 'prop-types';

import { createContext, useContext, useMemo, useState } from 'react';

const FormContext = createContext({
  form: {},
  guestId: '',
  setForm: () => {},
  setGuestId: () => {},
});

export const FormProvider = ({ children }) => {
  const [form, setForm] = useState();
  const [guestId, setGuestId] = useState();

  const value = useMemo(
    () => ({
      pdfData: {
        form_id: form?.form._id,
        form_name: form?.form.form_name,
        mandatory: form?.form.subjects[0].mandatory,
        pdf_fields: form?.form.subjects[0].pdf_fields,
        title: form?.form.subjects[0].title,
        project_id: form?.project_id,
        url: form?.form.url,
      },
      form,
      guestId,
      setGuestId,
      setForm,
    }),
    [form, setForm, guestId, setGuestId],
  );

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

FormProvider.propTypes = {
  children: PropTypes.any,
};

export const useFormContext = () => useContext(FormContext);
